import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useEffect, useRef } from 'react';
import Header from './components/Header/Header';
import Body from './components/Body/Body';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import './fonts/MyronHectorDemoOutline.ttf';
import './fonts/MyronHectorDemoRegular.ttf';
import SweepsInfo from './components/SweepsInfo/SweepsInfo';
import Affiliates from './components/Affiliates/Affiliates';
import Advertisers from './components/Advertisers/Advertisers';

function App() {
  const expertiseRef = useRef(null);
  const affiliatesRef = useRef(null);
  const advertisersRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="App">

    {/* <div className='maintenance-disclaimer'>
      <div><h2>🛠️</h2></div>
      <h1>SITE UNDER MAINTENANCE</h1>
      </div> */}


      <Header
        expertiseRef={expertiseRef}
        affiliatesRef={affiliatesRef}
        advertisersRef={advertisersRef}
        contactRef={contactRef}
      />
      <Body />
      <SweepsInfo expertiseRef={expertiseRef} />
      <Affiliates affiliatesRef={affiliatesRef} />
      <Advertisers advertisersRef={advertisersRef} />
      {/* <Contact contactRef={contactRef} /> */}
      <Footer contactRef={contactRef} />

      {/* <BrowserRouter>
        <Switch>
          <Redirect path="/" exact to="/home" />

          <Route exact path="/rewards/cash100" render={() => <Rewards pageName={'Cash 100'} />} />

          <Route path="*" render={() => <Rewards pageName={'Cash 250'} />}>
            <Redirect to="/" />
          </Route>
        </Switch>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
