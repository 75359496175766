import React from 'react';
import './Advertiser.css';
import FeatherIcon from 'feather-icons-react';


const Advertisers = ({advertisersRef}) => {
  return (
    <div className="advertisers-container" ref={advertisersRef}>
      <div>
      <FeatherIcon
              icon="star"
              className="right-section-item"
              size="85px"
              color="white"
            />
            <br></br><br></br>
        <h2 className="font-face-mhr">Advertisers</h2>
        <br></br>
        <div>
          <p>
            At Excelia Ads, we believe that high-quality traffic is the key to success in digital
            marketing. That’s why we specialize in driving targeted, high-quality traffic to your
            campaigns and landing pages. Our team of experts uses a range of tactics, including
            search engine optimization, social media advertising, and influencer marketing, to drive
            traffic to your site. We focus on targeting the right audience with the right message,
            ensuring that your campaigns are seen by the people who are most likely to convert. Our
            High Quality Traffic services include: – Traffic Generation: We use a range of tactics
            to drive high-quality traffic to your campaigns and landing pages.– Targeting: We focus
            on targeting the right audience with the right message, ensuring that your campaigns are
            seen by the people who are most likely to convert.– Real-Time Reporting: We provide
            real-time reporting and analytics, so you can monitor the performance of your campaigns
            and make data-driven decisions.– Ongoing Optimization: We continually optimize your
            campaigns to ensure maximum results and ROI.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Advertisers;
