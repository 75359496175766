import React from 'react'
import './Body.css'

const Body = () => {
  return (
    <div className='body-container'>
      
      <h1 className='body-main-title font-face-mhr'>Your Trusted Affiliate Marketing Partner</h1>
    </div>
  )
}

export default Body