import React from 'react';
import './SweepsInfo.css';
import FeatherIcon from 'feather-icons-react';


const SweepsInfo = ( {expertiseRef}) => {
  return (
    <div className="sweepsinfo-container"ref={expertiseRef} >
      <div>
      <br></br>
      <FeatherIcon
              icon="award"
              size="65px"
              color="white"
            />
      <br></br>
      <br></br>
        <h2 className="font-face-mhr" >CPL Sweepstakes</h2>
        <div>
          <br></br>
          <p>
            Excelia Ads is a leading affiliate marketing company that specializes in CPL
            Sweepstakes. We offer a range of unique pages and features that set us apart from the
            competition.
          </p>
          <p>
            We are experts when it comes to CPL Sweepstakes. We have an inventory of unique offers
            and high quality traffic. Our affiliates are happy with the high EPC's, fast payments
            terms and made-to-order offer concepts. On the other hand we make sure our advertisers
            get their money's worth when it comes to the acquisition, monetization and retention of
            users.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SweepsInfo;
