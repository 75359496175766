import React from 'react';
import './Footer.css';
import FeatherIcon from 'feather-icons-react';
import useMediaQuery from './../../hooks/useMediaQuery';

const Footer = ({ contactRef }) => {
  const isMobile = useMediaQuery('(max-width: 680px)');

  return (
    <div className="footer-container" ref={contactRef}>
      <div className="footer-left footer-item">
        <h3 className="font-face-mhr">ABOUT US</h3>
        <br></br>
        Excelia Ads is a leading digital marketing agency that specializes in CPL Sweepstakes. We
        offer a range of unique services and features that set us apart from the competition.
      </div>
      <div className="footer-mid footer-item">
        <div>
          <h3 className="font-face-mhr">CONNECT</h3> <br></br>
        </div>
        <div>
          <FeatherIcon icon="mail" size="20px" color="white" /> info@excelia-ads.com
        </div>
        <br></br>
        <div>
          <FeatherIcon icon="linkedin" size="20px" color="white" style={{ paddingBottom: '2px' }} />{' '}
          <a
            href="https://www.linkedin.com/company/excelia-ads/about"
            style={{ textDecoration: 'none', color: 'white' }}
            target="_blank"
          >
            LinkedIn
          </a>
        </div>
        <br></br>
        <div>
          <FeatherIcon icon="log-in" size="20px" color="white" />  <a href="https://excelia-ads.affise.com/v2/sign/in" style={{textDecoration: "none", color: 'white'}} target="_blank">
                Login
              </a>
        </div>
      </div>
      <div className="footer-right footer-item">
        {' '}
        <h3 className="font-face-mhr">COMPANY</h3>
        <div>
          <br></br>{' '}
          <FeatherIcon
            icon="briefcase"
            size="20px"
            color="white"
            style={{ paddingBottom: '2px' }}
          />{' '}
          EXCELIA ADS PTE. LTD.{' '}
        </div>
        <br></br>
        <div>
          {' '}
          <FeatherIcon icon="map-pin" size="20px" color="white" /> 10 Anson rd
        </div>
        <div>
          {' '}
          <FeatherIcon icon="map-ping" size="20px" color="white" /> 33-10C International Plaza
        </div>
        <div>
          <FeatherIcon icon="maffp" size="20px" color="white" /> Singapore
        </div>
        <br></br>
      </div>
    </div>
  );
};

export default Footer;
