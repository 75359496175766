import React, { useRef, useEffect, useState } from 'react';
import './Header.css';
import logoLong from '../../assets/logo long1 - bold.png';
import logoLongBW from '../../assets/logo long1-white.png';
import useMediaQuery from './../../hooks/useMediaQuery';
import FeatherIcon from 'feather-icons-react';

const Header = ({ expertiseRef, affiliatesRef, advertisersRef, contactRef }) => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const isMobile = useMediaQuery('(max-width: 831px)');

  const handleMenuClick = (ref) => {
    ref.current.scrollIntoView();
    setToggleMobileMenu(false);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {scrollPosition >= 50 ? (
        <img id="logo-long-header" src={logoLongBW} onClick={() => window.scrollTo(0, 0)}></img>
      ) : (
        <img id="logo-long-header" src={logoLong} onClick={() => window.scrollTo(0, 0)}></img>
      )}

      <div className="nav-bar">
        <div className="left-section"></div>

        {isMobile ? (
          <div>
            {' '}
            <FeatherIcon
              icon="menu"
              className="right-section-item"
              size="45px"
              color="white"
              onClick={() => setToggleMobileMenu((prev) => !prev)}
            />
            {toggleMobileMenu ? (
              <div
                className="mobile-menu  font-face-mhr"
                onClick={() => setToggleMobileMenu(false)}
              >
                <div>
                  <FeatherIcon
                    icon="menu"
                    size="40px"
                    color="white"
                    onClick={() => setToggleMobileMenu(false)}
                  />
                </div>
                <div className="mobile-menu-item" onClick={() => handleMenuClick(expertiseRef)}>
                  Expertise
                </div>{' '}
                <div className="mobile-menu-item" onClick={() => handleMenuClick(affiliatesRef)}>
                  Affiliates
                </div>{' '}
                <div className="mobile-menu-item" onClick={() => handleMenuClick(advertisersRef)}>
                  Advertisers
                </div>{' '}
                <div className="mobile-menu-item" onClick={() => handleMenuClick(contactRef)}>
                  Contact
                </div>{' '}
                <div className="mobile-menu-item">
                  {' '}
                  <a
                    href="https://excelia-ads.affise.com/v2/sign/in"
                    style={{ textDecoration: 'none', color: 'white' }}
                    target="_blank"
                  >
                    Login
                  </a>
                </div>{' '}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="right-section font-face-mhr">
            <div className="right-section-item" onClick={() => handleMenuClick(expertiseRef)}>
              Expertise
            </div>{' '}
            <div className="right-section-item" onClick={() => handleMenuClick(affiliatesRef)}>
              Affiliates
            </div>{' '}
            <div className="right-section-item" onClick={() => handleMenuClick(advertisersRef)}>
              Advertisers
            </div>{' '}
            <div className="right-section-item" onClick={() => handleMenuClick(contactRef)}>
              Contact
            </div>{' '}
            <div className="right-section-item">
              <a
                id="login-link"
                href="https://excelia-ads.affise.com/v2/sign/in"
                target="_blank"
              >
                Login
              </a>
            </div>{' '}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
