import React from 'react';
import './Affiliates.css';
import FeatherIcon from 'feather-icons-react';

const Affiliates = ({ affiliatesRef }) => {
  return (
    <div className="affiliates-container" ref={affiliatesRef}>
      <div>
        <br></br>
        <FeatherIcon icon="globe" size="65px" color="white" />
        <br></br>
        <br></br>

        <h2 className="font-face-mhr">Affiliates</h2>
        <br></br>
        <div>
          <p>
            We create unique offer concepts and angles that capture attention, drive engagement, and
            increase conversions for your traffic. At Excelia Ads, we understand that standing out
            in the crowded digital landscape is essential to success. That’s why we offer unique
            offers and angles that can give your brand the edge it needs to succeed. Our team of
            experts works with you to develop a customized offer concept that aligns with your brand
            messaging and resonates with your target audience. We use innovative techniques and
            strategies to create offers that capture attention, drive engagement, and increase
            conversions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Affiliates;
